export const selectors = {
    inputs: {
        // General
        inputTelType: 'input[type="tel"]',

        // ---------- Homepage ----------
        homePageAmountOI: '#homepage_one_installment_form_amount',
        homePageDaysOI: '#homepage_one_installment_form_days',
        homePagePromoCodeOI: '#homepage_one_installment_form_promoCode',

        homePageAmountTI: '#homepage_three_installments_form_amount',
        homePagePromoCodeTI: '#homepage_three_installments_form_promoCode',

        homePageCallMeBack: '#homepage-call-me-back',
        homePageCallMeBackPrefix: '#homepage-call-me-back_prefix',
        phoneNumberSelectHomepage: '#phone-number-select-homepage',

        // ---------- Registration ----------
        name: '#registration_name',
        surname: '#registration_surname',
        email: '#registration_email',
        phoneNumber: '#registration_phoneNumber',
        phoneNumberPrefix: '#registration_phoneNumber_prefix',
        nin: '#registration_nin',
        ninSk: '#registration_ninSk',
        netIncome: '#registration_netIncome',
        costs: '#registration_costs',
        idCardNumber: '#registration_idCardNumber',
        nationality: '#registration_nationality',
        accountNumber: '#registration_accountNumber',
        bank: '#registration_bank',
        approvalPersonalInfo: '#registration_approvalPersonalInfo',
        approvalMarketing: '#registration_approvalMarketing',
        pinCode: '#registration_activation_pincode',
        registrationPromoCode: '#registration_promoCode',
        phoneNumberSelectRegistration: '#phone-number-select-registration',
        prefixSelectWrapper: '.prefix-wrap-select',

        // Choice fields
        moneySource: 'input[name="registration[moneySource]"]',
        workingYears: 'input[name="registration[workingYears]"]',
        housing: 'input[name="registration[housing]"]',
        minorChildren: 'input[name="registration[minorChildren]"]',
        maritalStatus: 'input[name="registration[maritalStatus]"]',
        employerContract: 'input[name="registration[employerContract]"]',

        // Address
        street: '#registration_street',
        streetNumber: '#registration_streetNumber',
        city: '#registration_city',
        zipCode: '#registration_zipCode',
        hasDeliveryAddress: '#registration_hasDeliveryAddress',
        delStreet: '#registration_deliveryStreet',
        delStreetNumber: '#registration_deliveryStreetNumber',
        delCity: '#registration_deliveryCity',
        delZipCode: '#registration_deliveryZipCode',

        // HouseHold
        householdNetIncome: '#registration_householdIncome',
        householdCosts: '#registration_householdCosts',
        householdPersons: '#registration_householdPersons',
        householdPersonsWithIncome: '#registration_householdPersonsWithIncome',

        // Employer
        employer: '#registration_employer',
        employerPosition: '#registration_employerPosition',
        employerIc: '#registration_employerIc',
        employerContractEnd: '#registration_employerContractEnd',
        selfEmployerIc: '#registration_selfEmployerIc',
        selfEmployerSphere: '#registration_selfEmployerSphere',
        selfEmployerPlace: '#registration_selfEmployerPlace',

        // Activation
        activationApproval: '#registration_activation_approval',
        activationApproval2: '#registration_activation_approval2',
        activationApproval3: '#registration_activation_approval3',
        // ---------- Registration ----------

        // ---------- RegistrationComplete ----------
        netIncomeComplete: '#registration_complete_netIncome',
        costsComplete: '#registration_complete_costs',
        householdNetIncomeComplete: '#registration_complete_householdIncome',
        householdCostsComplete: '#registration_complete_householdCosts',
        householdPersonsComplete: '#registration_complete_householdPersons',
        householdPersonsWithIncomeComplete: '#registration_complete_householdPersonsWithIncome',
        employerComplete: '#registration_complete_employer',
        employerPositionComplete: '#registration_complete_employerPosition',
        employerIcComplete: '#registration_complete_employerIc',
        selfEmployerIcComplete: '#registration_complete_selfEmployerIc',
        selfEmployerSphereComplete: '#registration_complete_selfEmployerSphere',
        selfEmployerPlaceComplete: '#registration_complete_selfEmployerPlace',

        // Choice fields
        maritalStatusComplete: 'input[name="registration_complete[maritalStatus]"]',
        moneySourceComplete: 'input[name="registration_complete[moneySource]"]',
        housingComplete: 'input[name="registration_complete[housing]"]',
        minorChildrenComplete: 'input[name="registration_complete[minorChildren]"]',
        employerContractComplete: 'input[name="registration_complete[employerContract]"]',
        employerContractEndComplete: 'input[name="registration_complete[employerContractEnd]"]',
        workingYearsComplete: 'input[name="registration_complete[workingYears]"]',
        // ---------- RegistrationComplete ----------

        // ---------- Client ----------
        hasDeliveryAddressClient: '#customer_data_hasDeliveryAddress',
        clientLoanAmountOI: '#range-money-loan-1', // TODO LoanForm !!!
        clientLoanAmountTI: '#range-money-loan-2', // TODO LoanForm !!!
        clientLoanDaysOI: '#range-days-loan-1', // TODO LoanForm !!!

        // Login
        loginUsername: '#username',
        loginPassword: '#password',

        // Payment Schedule
        paymentSchedulePinCode: '#payment_schedule_activation_pincode',
        paymentScheduleApproval: '#payment_schedule_activation_approval',

        // Loan[Extension|TopUp]
        loanApproval: '#loan_activation_approval',
        loanApproval2: '#loan_activation_approval2',
        loanApproval3: '#loan_activation_approval3',
        loanFormDelAddressSwitcher: '#loan_form_deladdress_switcher',
        loanPinCode: '#loan_activation_pincode',
        loanExtensionApproval: '#loan_extension_activation_approval',
        loanExtensionPinCode: '#loan_extension_activation_pincode',
        loanTopUpApproval: '#loan_top_up_activation_approval',
        loanTopUpPinCode: '#loan_top_up_activation_pincode',
        loanPromoCode: '#loan_form_promoCode',
        // ---------- Client ----------

        // ---------- ClientComplete ----------
        ninClientComplete: '#customer_actualize_data_nin',
        employerClientComplete: '#customer_actualize_data_employer',
        employerPositionClientComplete: '#customer_actualize_data_employerPosition',
        employerContractEndClientComplete: '#customer_actualize_data_employerContractEnd',
        netIncomeClientComplete: '#customer_actualize_data_netIncome',
        costsClientComplete: '#customer_actualize_data_costs',
        selfEmployerIcClientComplete: '#customer_actualize_data_selfEmployerIc',
        selfEmployerSphereClientComplete: '#customer_actualize_data_selfEmployerSphere',
        selfEmployerPlaceClientComplete: '#customer_actualize_data_selfEmployerPlace',

        // HouseHold
        householdNetIncomeClientComplete: '#customer_actualize_data_householdIncome',
        householdCostsClientComplete: '#customer_actualize_data_householdCosts',
        householdPersonsClientComplete: '#customer_actualize_data_householdPersons',
        householdPersonsWithIncomeClientComplete: '#customer_actualize_data_householdPersonsWithIncome',

        // Choice fields
        employerContractClientComplete: 'input[name="customer_actualize_data[employerContract]"]',
        moneySourceClientComplete: 'input[name="customer_actualize_data[moneySource]"]',
        maritalStatusClientComplete: 'input[name="customer_actualize_data[maritalStatus]"]',
        housingClientComplete: 'input[name="customer_actualize_data[housing]"]',
        minorChildrenClientComplete: 'input[name="customer_actualize_data[minorChildren]"]',
        // ---------- ClientComplete ----------

        // ---------- ClientActualize Form ----------
        // Choice fields
        employerContractClientActualize: 'input[name="customer_data[employerContract]"]',
        moneySourceClientActualize: 'input[name="customer_data[moneySource]"]',
        maritalStatusClientActualize: 'input[name="customer_data[maritalStatus]"]',
        housingClientActualize: 'input[name="customer_data[housing]"]',
        minorChildrenClientActualize: 'input[name="customer_data[minorChildren]"]',

        // Bank
        banks: '#banks_bank',
        banksRedirectUrl: '#banks_redirect_url',
        banksActualizeSpinner: '#banks_actualize_spinner',
        banksActualizeAccountInfo: '#banks_actualize_account_info',

        // Unsubscribe
        phoneNumberUnsubscribe: '#sms_un_subscribe_phoneNumber',
        phoneNumberUnsubscribePrefix: '#sms_un_subscribe_phoneNumber_prefix',

        // Blog
        newsletter: '#newsletter-form',
        phoneNumberHelpMe: '#blog-help-me',
        phoneNumberHelpMePrefix: '#blog-help-me_prefix',
        phoneNumberSelectBlog: '#phone-number-select-blog',

        // Password change
        changePasswordFirst: '#change_password_password_first',
        changePasswordSecond: '#change_password_password_second',

        // Other
        resetPasswordNin: '#reset_password_identificationNumber',
        resetPasswordEmail: '#reset_password_email',
        passwordInputWrapper: '.input-password-wrapper',
        phoneNumberSelectSms: '#phone-number-select-sms',

        // ---------- Client Footer ----------
        callMeBackClientInput: '#call-me-back',
    },
    submits: {
        // Homepage
        homePageStep: '.homepage-step',

        // Registration
        registrationStep1: '.registration-step-1',
        registrationStep2: '.registration-step-2',
        registrationStep3: '.registration-step-3',
        registrationStep4: '.registration-step-4',
        registrationStepComplete: '.registration-step-complete',

        // Customer
        login: '#log-in',
        customerStepActualizeData: '#customer_actualize_data_save',
        loanFormSave: '#loan_form_save',

        // PromoCode
        promoCode: '.promocode-submit',

        // PinCodes
        stepPinCode: '#registration_activation_send',
        stepPaymentSchedulePinCode: '.j-submit-step-schedule',
        stepLoanPinCode: '.j-submit-step-loan-pincode',
        stepLoanExtensionPinCode: '.j-submit-step-loan-extension-pincode',
        stepLoanTopUpPinCode: '.j-submit-step-loan-top-up-pincode',

        // Blog
        helpMe: '#blog-help-me-button',
        newsletter: '#newsletter-button',

        // Other
        stepUnsubscribeSms: '.j-submit-step-unsubscribe-sms',
        unsubscribeSms: '#sms_un_subscribe_unsubscribe',
        resetPasswordSend: '#reset_password_send',
    },
    popovers: {
        all: '[data-toggle="popover"]',
        marketingInfo: '#marketing-info-popover',
    },
    sliders: {
        amount2Wrapper: '.slider-wrap.j-amountslider2', // homepage
        amount3Wrapper: '.slider-wrap.j-amountslider3', // registration
        date2Wrapper: '.slider-wrap.j-dateslider2', // homepage
        date3Wrapper: '.slider-wrap.j-dateslider3', // registration
        amount1WrapperMorePayments: '.slider-wrap.j-amountslider-more-payments-1', // homepage
        amount3WrapperMorePayments: '.slider-wrap.j-amountslider-more-payments-3', // registration
        amount1LoanWrapper: '.slider-wrap.j-amountslider-loan1',
        date1LoanWrapper: '.slider-wrap.j-dateslider-loan1',
        amount2LoanWrapperMorePayments: '.slider-wrap.j-amountslider-loan-more-payments1',
        amountBlogWrapper: '.slider-wrap.j-amountslider-blog',
        amountTopUpWrapper: '.slider-wrap.j-amountslider-top-up',
        link1Wrapper: '.slider-wrap.j-link1',
        link2Wrapper: '.slider-wrap.j-link2',
        calculatorTopUp: '#calculator-topUp',
        calculator: '#calculator',
        calculatorRegistration: '#calculator-registration',
        calculatorLoan: '#calculator-loan',
        sliderBlog: '.slider-blog',
        tabsContent: '.carousel-content',
        arrowPrev: '.slider-blog__arrow.left',
        arrowNext: '.slider-blog__arrow.right',
    },
    buttons: {
        burger: '.hamburger',
        btnPoznej: '.btnPoznej',
        showTableBtn: '#showTable',
        chevron: '#chevron',
        documentDownloadBtn: '#document-download-btn',
        ratingsSliderPrev: '#ratings .quoute-slider .prev',
        ratingsSliderNext: '#ratings .quoute-slider .next',
        menuBtn: '.menu',
        closeMenuBtn: '.button-close-sidenav',
        resendPinCode: '#resendPinCode',

        // CallMeBack
        callMeBackBtn: '#call-me-back-button',
        callMeBackClientBtn: '#call-me-back-button-client',

        // Kontomatik
        kontomatik: '#kontomatik-btn',
        kontomatikRefreshBtn: '#refreshKontomatik',
        selectBankKontomatik: '.select-bank__item',

        // Blog
        sliderBlog1Prev: '.slider-blog-1-prev',
        sliderBlog1Next: '.slider-blog-1-next',
        sliderBlog2Prev: '.slider-blog-2-prev',
        sliderBlog2Next: '.slider-blog-2-next',

        // Youtube
        fakePlayButton: '.fake-play-button',
        arrowLeftYT: '.tabs-content a.arrow.left',
        arrowRightYT: '.tabs-content a.arrow.right',

        // Client
        loanInstallmentCosts: '.loan-installment-costs',
        loanExtensionPickOption: '.loan-extension-pick .option label',
        closeBtn: 'button.close',
        clientSettingsButton: '#client-settings-button',

        // Password
        togglePasswordVisibility: '.password__toggle-visibility-button',
    },
    errors: {
        netIncomeCustom: '#input--mistake-netIncome',
        costsCustom: '#input--mistake-costs',
        householdPersonsWithIncomeCustom: '#input--mistake',
        kontomatikError: '#kontomatikError',
        kontomatikErrorText: '#kontomatikErText',
        alertBox: '.alert-box',
        changePassError: '#form-change-passwords .inputs label ul > li',
    },
    maps: {
        googleMap: '#google-map',
        mapCanvas: '#map-canvas',
        googleMaps: '#googleMaps',
    },
    loaders: {
        waitLoaderContainer: '.loader-container',
        waitLoader: '#wait-loader',

        // Kontomatik
        loaderBody: '#loaderBody',
    },
    forms: {
        // Registration
        registrationForm: 'form[name="registration"]',
        registrationFormComplete: 'form[name="registration_complete"]',
        registrationFormActivation: 'form[name="registration_activation"]',
        regiStep1: '#form-step-1',
        regiStep2: '#form-step-2',
        regiStep3: '#form-step-3',
        regiStep4: '#form-step-4',
        regiStepBack: 'a.form-step-back',
        callMeBackForm: '.call-me-back__form',

        addressDelivery: '#form-address-delivery',
    },
    content: {
        favicon: '#favicon',
        header: '#header',
        newDate: '.new-date',
        sideMenu: '#sidemenu',
        sideNavOld: '#mySidenav',

        // Blog
        newsletterContent: '.newsletter__content',
        newsletterAlert: '.newsletter-alert',
        helpMeAlert: '.blog-help-me-alert',

        // Career
        careerSectionWho: '.section-who',

        // Client
        customerInfoHeader: '.rollable-customer-info > .header',
        customerInfoHeaderChevron: '.rollable-customer-info > .header > i',
        customerInfoBody: '.rollable-customer-info > .body',
        clientSettingsMenu: '.client-settings-menu',

        // ????
        toggleCostText: '.toggleCost',
        loanExtensionDescription: '.loan-extension-pick .description span',

        // Clock
        actualTime: '.actual-time',
        actualTimePlus1: '.actual-time-plus-1',
        clock: '.clock',
        dashboardClock: '.dashboard-clock',
        daysSpan: '.days > span',
        hoursSpan: '.hours > span',
        minutesSpan: '.minutes > span',
        secondsSpan: '.seconds > span',

        // TopUp
        topUpBlock: '.top-up',
        daysToTopUp: '.days-to-top-up',
        hoursToTopUp: '.hours-to-top-up',

        // Compare loan
        compareLoanFee: '.compareLoanFee',
        comparePromoPercent: '.comparePromoPercent',

        // Kontomatik
        kontomatikManually: '#kontomatikManually',
        kontomatikWidget: '#kontomatik-frame',
        secondCard: '.cards.cards__second',
        kontomatikSelectBankContainer: '.kontomatik-select-bank',

        // Payment
        resendPinDiv: '#resendPinDiv',
        thePay: '#thePay',
        qrPayment: '#qrpayment',
        thePaySelect: '.thepay-select',
        logoImage: '.logoimage',
        bankPaymentWithThePay: '.bank-payment-the-pay',

        // Registration
        bankPayment: '#bankpayment',
        thePayMethodsDivContent: '#thepay-methods-div-content',
        countDownMinutes: '#count-down-timer .minutes span:first',
        countDownSeconds: '#count-down-timer .seconds-counter span:first',
        regisrationHiddenElements: '.registration-hidden',

        // Ratings
        ratings: '#ratings',
        ratingsTextsName: '#ratings .texts .name',
        ratingsTextsAge: '#ratings .texts .age',
        ratingsTextsText: '#ratings .texts .text',
        ratingsCount: '#ratings .stars .ratings-count',
        ratingsSection: '#ratings',

        // Youtube
        videoFrame: '.video-frame',

        // PinCode
        resendPincodeMessage: '.resend-pincode__message',
    },
    messages: {
        alertBox: 'section.alert-box',
        alertBoxPassChange: '.alert-box div.cell',
    },
    dropzone: {
        dropzone: '.dropzone',
        contentDocuments: '.content--documents',
        passwordAlert: '.document-password-alert',
        removeIcon: 'span.remove-icon',
        passwordSubmit: '#document-password-submit',
    },
}
